var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TsPanelCustomScroll',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ts-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'shop' })}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('update')))])]},proxy:true}])},[_c('ts-page-title',{attrs:{"title":_vm.$t('shop.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            { text: _vm.$t('shop.pageTitle'), href: '/admin/agency/shops' },
            {
                text: _vm.$t('create'),
                active: true
            }
        ]}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 tw-space-y-4 tw-mb-3 sm:tw-mb-0 lg:tw-mb-0"},[_c('BasicInfo',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('div',{staticClass:"col-md-8 tw-space-y-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('BankAccount',{staticClass:"tw-h-full",staticStyle:{"min-height":"200px"},attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ExchangeRate',{staticClass:"tw-h-full",staticStyle:{"min-height":"200px"},attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('h4',{staticClass:"tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"},[_vm._v(" "+_vm._s(_vm.$t('agencyProfile.address'))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('AddressShop',{attrs:{"validate":_vm.errors},on:{"loading":function (v) { return (_vm.loading = v); }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('br')],1),_c('div',{staticClass:"col-md-8"},[_c('GeoLocation',{ref:"goelocation",model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),(
                                    _vm.errors.has('geo_location.lat') ||
                                    _vm.errors.has('geo_location.lng')
                                )?_c('div',{staticClass:"tw-text-red-500"},[_vm._v(" "+_vm._s(_vm.errors.first('geo_location.lng'))+" ")]):_vm._e()],1)])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }